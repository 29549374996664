import styled from "styled-components";

const Main = styled.main`
  font-family: "Helvetica Neue", sans-serif;
  position: relative;
  width: 100%;

  table {
    border-collapse: collapse;
    overflow-x: auto;
    width: 100%;
  }

  tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.025);
  }

  tr.guest {
    background-color: rgba(0, 120, 0, 0.05);
  }

  th {
    border: 1px solid #ddd;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.5em 0.25em;
    position: sticky;
    top: 0;
    text-align: left;
  }

  td {
    border: 1px solid #ddd;
    padding: 0.5em 0.25em;
    position: relative;
  }

  a {
    display: block;
    font-weight: 600;
    height: 100%;
    position: relative;
    text-decoration: none;
    width: 100%;
  }

  .divider {
    background: maroon;
    color: #fff;
    padding-bottom: 1em;
    padding-top: 1em;
    text-align: center;
  }
`;

export default Main;
