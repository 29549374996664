import * as React from "react";

import { Link } from "gatsby";
import Main from '../components/Main';

// markup
const IndexPage = () => {
  return (
    <Main>
      <h1>April Show at The Oak House</h1>
      <table>
        <thead>
          <tr>
            <th>Title / Link</th>
            <th>Artist</th>
            <th>Lyrics</th>
            <th>Chords</th>
            <th>Guest</th>
            <th>NA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="cover">
              <Link to="/covers/3-am">3 AM</Link>
            </td>
            <td>Matchbox 20</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/6th-avenue-heartache">6th Ave Heartache</Link>
            </td>
            <td>The Wallflowers</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/20-good-reasons">20 Good Reasons</Link>
            </td>
            <td>Thirsty Merc</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/astrovan">Astrovan</Link>
            </td>
            <td>Mt Joy</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/babylon">Babylon</Link>
            </td>
            <td>David Gray</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/bird-stealing-bread">Bird Stealing Bread</Link>
            </td>
            <td>Iron & Wine</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/californication">Californication</Link>
            </td>
            <td>Red Hot Chili Peppers</td>
            <td>✔</td>
            <td></td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/clarity">Clarity</Link>
            </td>
            <td>John Mayer</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/country-road">Country Road</Link>
            </td>
            <td>James Tayor</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/cover-me-up">Cover Me Up</Link>
            </td>
            <td>Jason Isbell</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/crescent-moon">Crescent Moon</Link>
            </td>
            <td>Conor Albert & James Smith</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/dead-sea">Dead Sea</Link>
            </td>
            <td>The Lumineers</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/dont-panic">Don't Panic</Link>
            </td>
            <td>Coldplay</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/eliza-jane">Eliza Jane</Link>
            </td>
            <td>David Ramirez</td>
            <td>✔</td>
            <td></td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/everlong">Everlong</Link>
            </td>
            <td>Foo Fighters</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/every-morning">Every Morning</Link>
            </td>
            <td>Sugar Ray</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td>✔</td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/fire-and-rain">Fire & Rain</Link>
            </td>
            <td>James Taylor</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/fruits-of-my-labor">Fruits of My Labor</Link>
            </td>
            <td>Lucinda Williams</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/georgia">Georgia</Link>
            </td>
            <td>Vance Joy</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/give-me-one-reason">Give Me One Reason</Link>
            </td>
            <td>Tracy Chapman</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/halfway-up">Halfway Up</Link>
            </td>
            <td>The Brook & The Bluff</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/hard-place">Hard Place</Link>
            </td>
            <td>H.E.R.</td>
            <td>✔</td>
            <td></td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/high-and-dry">High & Dry</Link>
            </td>
            <td>Radiohead</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/how-do-you-get-em-back">
                How Do You Get Em Back
              </Link>
            </td>
            <td>David Ramirez</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/i-can-change">I Can Change</Link>
            </td>
            <td>Lake Street Dive</td>
            <td>✔</td>
            <td></td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/i-will-remain">I Will Remain</Link>
            </td>
            <td>Matthew & The Atlas</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/if-i-ever-lose-my-faith-in-you">
                If I Ever Lose My Faith In You
              </Link>
            </td>
            <td>Sting</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/if-you-could-only-see">
                If You Could Only See
              </Link>
            </td>
            <td>Tonic</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/imitosis">Imitosis</Link>
            </td>
            <td>Andrew Bird</td>
            <td>✔</td>
            <td></td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/just-the-two-of-us">Just the Two of Us</Link>
            </td>
            <td>Bill Withers</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/keep-it-loose-keep-it-tight">Keep it Loose, Keep it Tight</Link>
            </td>
            <td>Amos Lee</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/late-night-talking">Late Night Talking</Link>
            </td>
            <td>Harry Styles</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/laundry-room">Laundry Room</Link>
            </td>
            <td>The Avett Brothers</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/levitating">
                Levitating
              </Link>
            </td>
            <td>Dua Lipa</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/little-lion-man">Little Lion Man</Link>
            </td>
            <td>Mumford & Sons</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/lost-in-japan">Lost in Japan</Link>
            </td>
            <td>Shawn Mendes</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/maneater">Maneater</Link>
            </td>
            <td>Hall & Oates</td>
            <td>✔</td>
            <td></td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover no-lyrics">
              <Link to="/covers/mariners-revenge-song">Mariner's Revenge Song</Link>
            </td>
            <td>The Decemberists</td>
            <td>✔</td>
            <td></td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover no-lyrics">
              <Link to="/covers/meet-virginia">Meet Virginia</Link>
            </td>
            <td>Train</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/my-favorite-mistake">
                My Favorite Mistake
              </Link>
            </td>
            <td>Sheryl Crow</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/neon">
                Neon
              </Link>
            </td>
            <td>John Mayer</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/older-chests">Older Chests</Link>
            </td>
            <td>Damien Rice</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/rich-girl">Rich Girl</Link>
            </td>
            <td>Hall & Oates</td>
            <td>✔</td>
            <td></td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/rivers-and-roads">Rivers & Roads</Link>
            </td>
            <td>The Head & The Heart</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/royals">Royals</Link>
            </td>
            <td>Lorde</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/say-you-wont-let-go">Say You Won't Let Go</Link>
            </td>
            <td>James Arthur</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/semi-charmed-life">Semi Charmed Life</Link>
            </td>
            <td>Third Eye Blind</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/slide">Slide</Link>
            </td>
            <td>Goo Goo Dolls</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/stay-with-you">Stay With You</Link>
            </td>
            <td>John Legend</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/stop-this-train">Stop This Train</Link>
            </td>
            <td>John Mayer</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/summertime-magic">Summertime Magic</Link>
            </td>
            <td>Childish Gambino</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/sunday-morning">Sunday Morning</Link>
            </td>
            <td>Maroon 5</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/sweet-pea">Sweet Pea</Link>
            </td>
            <td>Amos Lee</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td>✔</td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/tangled">Tangled</Link>
            </td>
            <td>Maroon 5</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/the-a-team">The A Team</Link>
            </td>
            <td>Ed Sheeran</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/the-bad-days">The Bad Days</Link>
            </td>
            <td>David Ramirez</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/the-crane-wife-3">The Crane Wife 3</Link>
            </td>
            <td>The Crane Wife 3</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/the-stable-song">The Stable Song</Link>
            </td>
            <td>Gregory Alan Isakov</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/trouble">Trouble</Link>
            </td>
            <td>Ray LaMontagne</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/vienna">Vienna</Link>
            </td>
            <td>Billy Joel</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/wake-me-up">Wake Me Up</Link>
            </td>
            <td>Avicii + Aloe Blacc</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/whats-been-going-on">What's Been Going On</Link>
            </td>
            <td>Amos Lee</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td>✔</td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/why-georgia">Why Georgia</Link>
            </td>
            <td>John Mayer</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover no-lyrics">
              <Link to="/covers/wind-cries-mary">Wind Cries Mary</Link>
            </td>
            <td>Jimi Hendrix</td>
            <td>✔</td>
            <td></td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr className="guest">
            <td className="cover">
              <Link to="/covers/yellow-eyes">Yellow Eyes</Link>
            </td>
            <td>Rayland Baxter</td>
            <td>✔</td>
            <td>✔</td>
            <td>Rob</td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/you-are-the-best-thing">
                You Are The Best Thing
              </Link>
            </td>
            <td>Ray LaMontagne</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="cover">
              <Link to="/covers/younger">Younger</Link>
            </td>
            <td>Seinabo Sey</td>
            <td>✔</td>
            <td>✔</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th class="divider" colSpan={6}>ORIGINALS</th>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/baby-i-dont-know">Baby I Don't Know</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/black-and-white">Black and White</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/burn-out">Burn Out</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/can-i-be-honest">Can I Be Honest</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/days-are-going-fast">
                Days Are Going Fast
              </Link>
            </td>
            <td>Michael Weslander & Rob Andrews</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/devious">Devious</Link>
            </td>
            <td>Michael Weslander & Andrew Strong</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/germain-towers">Germain Towers</Link>
            </td>
            <td>Michael Weslander & Neil Siekman</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/hold-on-to-tomorrow">
                Hold On To Tomorrow
              </Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/i-would-have-left">I Would Have Left</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/lockdown-love">
                Lockdown Love (You know me)
              </Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/march">March (Hurry Up And Wait)</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/of-consequence">Of Consequence</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/rather-be-lonely">Rather Be Lonely</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/rescue-me">Rescue Me</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/rushing-back">Rushing Back</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/silver-screen">Silver Screen</Link>
            </td>
            <td>Michael Weslander & John Schrad</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/where-does-that-leave-us">
                Where Does That Leave Us
              </Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="original">
              <Link to="/originals/wrong-side-of-town">Wrong Side Of Town</Link>
            </td>
            <td>Michael Weslander</td>
            <td>✔</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </Main>
  );
};

export default IndexPage;
